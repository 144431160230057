import { FC, memo, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";

import styles from "./Header.module.scss";
import { useModal } from "src/hooks";
import { ROUTES } from "src/constants";
import { arrayInsert } from "src/utils";
import { CreateDashboardModal } from "src/features";
import { Button, MenuDropdown, Tooltip } from "src/components";
import { MenuOption } from "src/components/MenuDropdown/types";
import { Hamburger, Library, Magnifier, Plus } from "src/assets/icons";
import {
  selectWhiteLabel,
  selectCompanyTrackersCount,
} from "src/store/selectors";

// Inner imports
import { ProfileButton, HeaderMessage } from "./components";

type Props = {
  showGlobalSearch: () => void;
};

export const Header: FC<Props> = memo(({ showGlobalSearch }) => {
  const { t } = useTranslation();

  const history = useHistory();

  const { setModal } = useModal();

  const trackersCount = useSelector(selectCompanyTrackersCount);

  const {
    name,
    logos: { small, medium },
  } = useSelector(selectWhiteLabel);

  const isCompileDashboardShown = useMemo<boolean>(
    () => trackersCount > 0,
    [trackersCount],
  );

  const onExploreClick = useCallback(
    (): void => history.push(ROUTES.exploreTrend),
    [history],
  );

  const onCreateDashboardClick = useCallback(
    (): void => history.push(ROUTES.createTracker),
    [history],
  );

  const onCompileDashboardClick = useCallback(
    (): void => setModal("create-dashboard", <CreateDashboardModal />),
    [setModal],
  );

  const onGenerateDashboardClick = useCallback(
    (): void => history.push(ROUTES.generateTracker),
    [history],
  );

  const options = useMemo<MenuOption[]>(() => {
    const options: MenuOption[] = [
      {
        icon: "Plus",
        onClick: onCreateDashboardClick,
        label: t("component.header.tooltip.create_dashboard"),
      },
      {
        icon: "Stars",
        onClick: onGenerateDashboardClick,
        label: t("component.header.tooltip.generate_dashboard"),
      },
      {
        icon: "Magnifier",
        onClick: onExploreClick,
        label: t("component.header.tooltip.search_dashboard"),
      },
      {
        icon: "Library",
        onClick: showGlobalSearch,
        label: t("component.header.tooltip.global_search"),
      },
    ];

    if (isCompileDashboardShown)
      arrayInsert(options, 2, {
        icon: "Generate",
        onClick: onCompileDashboardClick,
        label: t("component.header.tooltip.compile_dashboard"),
      });

    return options;
  }, [
    t,
    onExploreClick,
    showGlobalSearch,
    onCreateDashboardClick,
    onCompileDashboardClick,
    isCompileDashboardShown,
    onGenerateDashboardClick,
  ]);

  const createEntityOptions = useMemo<MenuOption[]>(() => {
    const options: MenuOption[] = [
      {
        icon: "Plus",
        onClick: onCreateDashboardClick,
        label: t("component.header.tooltip.create_dashboard"),
      },
      {
        icon: "Stars",
        onClick: onGenerateDashboardClick,
        label: t("component.header.tooltip.generate_dashboard"),
      },
    ];

    if (isCompileDashboardShown)
      arrayInsert(options, 2, {
        icon: "Generate",
        onClick: onCompileDashboardClick,
        label: t("component.header.tooltip.compile_dashboard"),
      });

    return options;
  }, [
    t,
    onCreateDashboardClick,
    onCompileDashboardClick,
    isCompileDashboardShown,
    onGenerateDashboardClick,
  ]);

  const hasMultipleCreateEntityOptions = useMemo<boolean>(
    () => createEntityOptions.length > 1,
    [createEntityOptions],
  );

  return (
    <header className={styles.wrapper}>
      <Link to={ROUTES.DEFAULT_ROUTE} className={styles.logo}>
        <picture>
          <source media="(max-width:768px)" srcSet={small} />
          <img src={medium} alt={name} />
        </picture>
      </Link>
      <div className={styles.buttonsSection}>
        <HeaderMessage />
        {hasMultipleCreateEntityOptions ? (
          <MenuDropdown
            options={createEntityOptions}
            dropdownClassName={styles.dropdown}
            className={styles.buttonDesktopWrapper}
            dropdownDirection={{ y: "down", x: "left" }}
          >
            <Tooltip content={t("component.header.tooltip.create_dashboard")}>
              <Button buttonSize="small" className={styles.button}>
                <Plus />
              </Button>
            </Tooltip>
          </MenuDropdown>
        ) : (
          <Tooltip content={t("component.header.tooltip.create_dashboard")}>
            <Button
              buttonSize="small"
              className={styles.button}
              onClick={onCreateDashboardClick}
            >
              <Plus />
            </Button>
          </Tooltip>
        )}
        <Tooltip
          content={t("component.header.tooltip.search_dashboard")}
          className={styles.buttonDesktopWrapper}
        >
          <Button
            buttonSize="small"
            className={styles.button}
            onClick={onExploreClick}
          >
            <Magnifier />
          </Button>
        </Tooltip>
        <Tooltip
          content={t("component.header.tooltip.global_search")}
          className={styles.buttonDesktopWrapper}
        >
          <Button
            buttonSize="small"
            className={styles.button}
            onClick={showGlobalSearch}
          >
            <Library />
          </Button>
        </Tooltip>
        <MenuDropdown
          options={options}
          dropdownClassName={styles.dropdown}
          className={styles.buttonTabletWrapper}
          dropdownDirection={{ y: "down", x: "left" }}
        >
          <Button buttonSize="small" className={styles.button}>
            <Hamburger />
          </Button>
        </MenuDropdown>
        <ProfileButton />
      </div>
    </header>
  );
});
