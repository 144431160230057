import { useMemo, useContext } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";

import styles from "./TableCell.module.scss";
import { MenuDropdown } from "src/components";
import { genericMemo } from "src/utils";

// Inner imports
import type { TableItemGenericProps, TableMenuOption } from "../../types";
import type {
  TextCellProps,
  TableCellProps,
  TableCardCellProps,
  TableCellMenuProps,
  TextTableCardCellProps,
  ClickableTableCellProps,
} from "./types";
import { TableContext } from "../../context";
import { TableItemToggler } from "../index";
import { getTableColumnProps } from "./utils";

export const ClickableTableCell = genericMemo(
  <T extends TableItemGenericProps>({
    item,
    tableColumn,
  }: ClickableTableCellProps<T>): JSX.Element => {
    const { title, value } = getTableColumnProps({ item, tableColumn });

    return (
      <button
        className={styles.cellClickable}
        onClick={() => tableColumn.onClick?.(item)}
        title={title}
      >
        {value as string}
      </button>
    );
  },
);

export const TextTableCell = genericMemo(
  <T extends TableItemGenericProps>({
    item,
    tableColumn,
  }: TextCellProps<T>): JSX.Element => {
    const { title, value, Icon } = getTableColumnProps({ item, tableColumn });

    return (
      <span title={title}>
        {Icon && <Icon />}
        <span>{value as string}</span>
      </span>
    );
  },
);

export const TextTableCardCell = genericMemo(
  <T extends TableItemGenericProps>({
    item,
    tableColumn,
    className,
  }: TextTableCardCellProps<T>): JSX.Element => {
    const { title, value } = getTableColumnProps({ item, tableColumn });

    return (
      <div className={className || styles.tableCardCellValue} title={title}>
        {value as string}
      </div>
    );
  },
);

export const TableCellMenu = genericMemo(
  ({ options, className }: TableCellMenuProps): JSX.Element => {
    const { contentRef } = useContext(TableContext);

    return (
      <MenuDropdown
        options={options}
        className={className || styles.cellMenuDropdown}
        ref={contentRef}
      />
    );
  },
);

export const TableCell = genericMemo(
  <T extends TableItemGenericProps>({
    item,
    tableColumn,
    isRowCollapsed,
    toggleCollapse,
    getActionMenuOptions,
    index,
  }: TableCellProps<T>): JSX.Element | null => {
    const { key, isAction, isNumeric, style } = tableColumn;

    const isFirstColumn = index === 0;

    const hasSubTable = Boolean(
      item?.subTableProps?.tableColumns?.length &&
        item?.subTableProps?.items?.length,
    );

    const menuOptions = useMemo<TableMenuOption[]>(
      () => getActionMenuOptions?.(item) || [],
      [getActionMenuOptions, item],
    );

    const isCellMenuVisible = useMemo<boolean>(
      () => Boolean(menuOptions.length),
      [menuOptions.length],
    );

    const Element = useMemo<JSX.Element | null>(() => {
      const { getComponent } = tableColumn;

      const { value, isClickable } = getTableColumnProps({ item, tableColumn });

      if (getComponent) return getComponent(item) || null;

      if (!value) return null;

      if (isClickable)
        return <ClickableTableCell item={item} tableColumn={tableColumn} />;

      return <TextTableCell item={item} tableColumn={tableColumn} />;
    }, [item, tableColumn]);

    return (
      <td
        key={String(key)}
        className={cx(
          isAction ? styles.cellAction : "",
          isNumeric ? styles.cellNumeric : "",
        )}
        style={style}
      >
        <span>
          {isFirstColumn && hasSubTable && (
            <TableItemToggler
              isCollapsed={isRowCollapsed}
              toggleCollapse={toggleCollapse}
            />
          )}
          {Element}
        </span>
        {isCellMenuVisible && <TableCellMenu options={menuOptions} />}
      </td>
    );
  },
);

export const TableCardCell = genericMemo(
  <T extends TableItemGenericProps>({
    item,
    tableColumn,
  }: TableCardCellProps<T>) => {
    const { t } = useTranslation();

    const { key, labelKey } = tableColumn;

    const label = t(labelKey);

    const Element = useMemo<JSX.Element | null>(() => {
      const { value, isClickable } = getTableColumnProps({ item, tableColumn });

      if (!value) return null;

      if (isClickable)
        return <ClickableTableCell item={item} tableColumn={tableColumn} />;

      return (
        <TextTableCardCell
          className={styles.tableCardCellValue}
          item={item}
          tableColumn={tableColumn}
        />
      );
    }, [item, tableColumn]);

    return (
      <div key={String(key)} className={styles.tableCardCell}>
        <div className={styles.tableCardCellLabel} title={label}>
          {label}
        </div>
        {Element}
      </div>
    );
  },
);
