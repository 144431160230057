import { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { BubbleChat, FullPageChat } from "flowise-embed-react";

import { getHostDomain } from "src/utils";
import { selectCompanyId, selectUserId } from "src/store/selectors";

// Inner imports
import { BubbleAgentChatProps, FullPageAgentChatProps } from "./types";
import {
  BUBBLE_AGENT_CHAT_PROPS,
  FULL_PAGE_AGENT_CHAT_PROPS,
} from "./constants";

const { REACT_APP_API_BASE_URL = "", REACT_APP_FLOWISE_AGENT_API_HOST = "" } =
  process.env;

type Props = {
  type: "fullPage" | "bubble";
  variables?: Record<string, unknown>;
  chatflowId: string;
};

export const AgentChat: FC<Props> = ({ type, variables, chatflowId }) => {
  const companyId = useSelector(selectCompanyId);

  const userId = useSelector(selectUserId);

  const whiteLabelDomainId = useMemo<string>(() => getHostDomain(), []);

  const flowConfiguration = useMemo<Record<string, unknown>>(
    () => ({
      vars: {
        companyId,
        authorId: userId,
        whiteLabelDomainId,
        apiURL: REACT_APP_API_BASE_URL,
        ...variables,
      },
    }),
    [companyId, userId, variables, whiteLabelDomainId],
  );

  const botProps = useMemo<
    FullPageAgentChatProps | BubbleAgentChatProps
  >(() => {
    const defaultAgentChatProps =
      type === "fullPage"
        ? FULL_PAGE_AGENT_CHAT_PROPS
        : BUBBLE_AGENT_CHAT_PROPS;

    return {
      ...defaultAgentChatProps,
      apiHost: REACT_APP_FLOWISE_AGENT_API_HOST,
      chatflowid: chatflowId,
      chatflowConfig: flowConfiguration,
    };
  }, [chatflowId, flowConfiguration, type]);

  if (!chatflowId) return null;

  if (type === "fullPage") return <FullPageChat {...(botProps as any)} />;

  if (type === "bubble") return <BubbleChat {...(botProps as any)} />;

  return null;
};
