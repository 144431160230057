import { BubbleAgentChatProps, FullPageAgentChatProps } from "./types";

export const FULL_PAGE_AGENT_CHAT_PROPS: FullPageAgentChatProps = {
  theme: {
    customCSS: `
      .icon-tabler-refresh {
        stroke: var(--color-font-dark) !important;
      }

      .chatbot-host-bubble {
        background-color: var(--color-background-light) !important;
      }
    `,
    chatWindow: {
      titleBackgroundColor: "transparent",
      height: window.innerHeight - 138,
      welcomeMessage: "What's the topic you'd like to analyse?",
      userMessage: {
        backgroundColor: "var(--color-brand)",
        textColor: "var(--color-font-dark)",
      },
      botMessage: {
        backgroundColor: "var(--color-background-light)",
        textColor: "var(--color-font-dark)",
      },
      feedback: { color: "var(--color-font-dark)" },
      backgroundColor: "var(--color-background-white)",
      footer: { showFooter: false },
      textInput: { sendButtonColor: "var(--color-font-dark)" },
    },
  },
};

export const BUBBLE_AGENT_CHAT_PROPS: BubbleAgentChatProps = {
  theme: {
    customCSS: `
      .icon-tabler-refresh {
        stroke: var(--color-font-dark) !important;
      }

      .chatbot-host-bubble {
        background-color: var(--color-background-light) !important;
      }
    `,
    button: {
      backgroundColor: "var(--color-brand)",
      iconColor: "var(--color-font-dark)",
    },
    chatWindow: {
      titleBackgroundColor: "var(--color-brand)",
      userMessage: {
        textColor: "var(--color-font-dark)",
        backgroundColor: "var(--color-brand)",
      },
      botMessage: { textColor: "var(--color-font-dark)" },
      feedback: { color: "var(--color-font-dark)" },
      textInput: { sendButtonColor: "var(--color-font-dark)" },
      backgroundColor: "var(--color-background-secondary-light)",
      footer: { showFooter: false },
    },
  },
};
